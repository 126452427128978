<template>
  <CCollapse :show="showCollapase">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CCard>
      <CCardHeader color="dark text-white">
        <CRow>
          <CCol sm="11" class="d-flex ">{{TitleModal}}</CCol>
          <CCol sm="1" class="d-flex justify-content-end">
            <button type="button" aria-label="Close" class="close" @click="closeCollapse">×</button>
          </CCol>
        </CRow>
      </CCardHeader>
        <CCol sm="12" lg="12" class="mt-2">
          <CInput
            :label="$t('label.packagingName')+' '+('(ES)')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            :placeholder="$t('label.name')+' '+('(ES)')"
            v-uppercase
            maxlength="20"
            v-model.trim="$v.PackagingNameEs.$model"
            :is-valid="hasError($v.PackagingNameEs)"
            :invalid-feedback="errorMessage($v.PackagingNameEs)"
          />
        </CCol>
        <CCol sm="12" lg="12" class="mt-2">
          <CInput
            :label="$t('label.packagingName')+' '+('(EN)')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            :placeholder="$t('label.name')+' '+('(EN)')"
            v-uppercase
            maxlength="20"
            v-model.trim="$v.PackagingNameEn.$model"
            :is-valid="hasError($v.PackagingNameEn)"
            :invalid-feedback="errorMessage($v.PackagingNameEn)"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <div class="form-group form-row"  >
            <label class="text-right col-sm-12 col-lg-4 mt-2 required">{{ $t('label.weight')+' (KG)' }}</label>
            <div class="input-group col-sm-12 col-lg-7">
              <money
                v-uppercase
                v-model.trim="Weight"
                v-bind="measure" 
                :class="ValidateFormControl(Weight)"
              >
              </money>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(Weight) < 0)">
                {{$t('label.ValueNotNegative')}}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((Weight == 0 || Weight == '0,0000') && valid==true)">
                {{$t('label.required')}}
              </CCol>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="12" v-if="isEdit">
          <CSelect
            :label="$t('label.status')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            v-model="Status"
            :value.sync="Status"
            :is-valid="statusSelectColor"
            :options="statusOptions"
          />
        </CCol>
        
      <CCardFooter>
        <div class="row justify-content-end">
 
            <CButton
              color="add"
              :disabled="isSubmit"
              @click.stop="isEdit?statusConfirmation(packagingEdit.FgActPackaging, Status, submit):submit()"
              class="btn d-flex align-items-center m-2 btn-add"
            >
              <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
              <div v-if="isSubmit">
                    <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="ml-1">{{$t('button.accept')}}</span>
              </div>
            </CButton>
            <CButton
              color="wipe"
              @click="closeCollapse"
              class="btn d-flex align-items-center btn-wipe mb-2 mt-2"
            >
              <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
            </CButton>

        </div>
      </CCardFooter>
    </CCard>
  </CCollapse> 
</template>

<script>
import UpperCase from '@/_validations/uppercase-directive';
import EmbalajeVal from '@/_validations/embalaje/embalajeVal';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { VMoney, Money } from "v-money";
import { NumberFormater } from '@/_helpers/funciones';

//DATA
function data() {
  return {
    // VARIABLES
    valid: false,
    AddModal: false,
    Loading: false,
    isEdit: false,
    showCollapase: false,
    //MODELO
    PackagingNameEs: '',
    PackagingNameEn: '',
    Weight: '',
    Status: '',
    PackagingId: '',
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 14,
    },
  };
}
//METODOS
function ValidateFormControl(value) { 
  value = this.ReverseFormat(value);

  if (((value == 0 || value == '0,0000') && this.valid==true) || value < 0  ) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

function ReverseFormat(number) {
    return NumberFormater.setNum(number);
}

function resetForm() {
  this.PackagingNameEs = '';
  this.PackagingNameEn = '';
  this.Weight = '';
  this.Status = '';
  this.PackagingId = '';
  this.isEdit = false;
  this.valid = false;
  this.$v.$reset();
}

function closeCollapse() {
  this.$emit('close-collapse');
}

function submit() {
  try {
    this.valid = true;
    this.$v.$touch();
    this.Loading = true;
    let res = [];
    let metodo = '';
    let ruta = '';
    let PackagingJson = [];

    if ((this.$v.$error )|| (this.Weight == '') || (this.Weight =='0,00') || (this.Weight== 0) ) {
      this.Loading = false;
      throw this.$t("label.errorsPleaseCheck");
    }

    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.isEdit) {
      PackagingJson = {
        PackagingId: this.PackagingId,
        PackagingNameEs: this.PackagingNameEs,
        PackagingNameEn: this.PackagingNameEn,
        Weight: ReverseFormat(this.Weight),
        Status: this.Status,
      };
      metodo = 'PUT';
      ruta = 'Packaging-update';
    } else {
      PackagingJson = {
        PackagingNameEs: this.PackagingNameEs,
        PackagingNameEn: this.PackagingNameEn,
        Weight: ReverseFormat(this.Weight),
      };
      metodo = 'POST';
      ruta = 'Packaging-insert';
    }
    //ENVIANDO POST PARA GUARDAR O ACTUALIZAR
    this.$http
      .ejecutar(metodo, ruta, PackagingJson, {
        root: 'PackagingJson',
      })
      .then((response) => {
        res = [...response.data.data];
        this.resetForm();
        this.closeCollapse();
        this.notifySuccess({ text: res[0].Response });
        this.Loading = false;
        this.$emit('refresh-packaging');
      })
      .catch((err) => {
        this.notifyError({ text: err });
        this.Loading = false;
      });
  } catch (e) {
    this.Loading = false;
    this.notifyError({ text: e });
  }
  
}

function setEditForm(packaging){
  this.PackagingId = packaging.PackagingId;
  this.isEdit = true;
  this.Status = packaging?.FgActPackaging?1:0;
  this.PackagingNameEs = packaging?.PackagingNameEs ?? '';
  this.PackagingNameEn = packaging?.PackagingNameEn ?? '';
  this.Weight = packaging.Weight ? ReverseFormat(packaging.Weight) : 0;
  this.$v.$touch();
}

function TitleModal() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let title = this['PackagingName'+_lang]
  return this.isEdit ? this.$t('label.edit')+' '+this.$t('label.packaging')+': '+title : this.$t('label.nueva')+' '+this.$t('label.packaging')
}

export default {
  name: 'collpaseEmbalaje',
  mixins: [
    GeneralMixin,
    ModalMixin,
  ],
  data,
  props: {
    collapse: Boolean,
    packagingEdit: Object
  },
  components: {
    Money,
},
  directives: UpperCase,
  validations: EmbalajeVal,
  watch: {
    collapse: function(val) {
      this.showCollapase = val;
      if(!val)
        this.resetForm();
    },
    packagingEdit: function(val) {
      this.setEditForm(val);
    }
  },
  methods: {
    ValidateFormControl,
    ReverseFormat,
    resetForm,
    setEditForm,
    submit,
    closeCollapse
  },
  computed: {
    //isDisabled,
    TitleModal
  },
};
</script>
<style scoped></style>
